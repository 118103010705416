var toggleClass = (function () {
  var self = {};

  var toggleContainer = function (elements, classList) {
    for (let i = 0, n = elements.length; i < n; i++) {
      elements[i].classList.toggle(classList);
    };
  }

  var matchesCheck = function () {
    // IE support: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
  }

  var closestCheck = function () {
    // IE support: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
    }
  }

  var polyfillCheck = function () {
    matchesCheck();
    closestCheck();
  }

  var toggleSelfClass = function (element, bemCss) {
    if (bemCss == true) {
      let bemToggled = element.classList[0] + '--toggled';
      element.classList.toggle(bemToggled);
    } else {
      element.classList.toggle('toggled');
    }
  }

  var toggle = function (event, element) {
    let dataAttrs = element.dataset;
    let bemCss = new Boolean(dataAttrs['bem'] == 'true');

    toggleContainer(
      document.querySelectorAll(dataAttrs['container']),
      dataAttrs['class']
    );

    toggleSelfClass(element, bemCss)

    if (dataAttrs['return']) { event.preventDefault(); }
  }

  var addEvent = function (parent, evt, selector, handler) {
    parent.addEventListener(evt, function (event) {
      if (event.target.matches(selector + ', ' + selector + ' *')) {
        handler.apply(event.target.closest(selector), arguments);
      }
    }, false);
  }

  self.init = function (selector) {
    polyfillCheck();
    addEvent(document, 'click', selector, function (e) {
      toggle(e, this);
    });
  }

  return self;
}());

toggleClass.init('.toggle-class');
