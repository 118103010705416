import $ from 'jquery';

function amendLeedsBillingFields() {
  var selectedId = $('#school_schools_local_authority_id option:selected').text();
  if(selectedId == 'Leeds') {
    $('.fieldset--billing').hide();
  } else {
    $('.fieldset--billing').show();
  }
}

$(document).on('ready turbolinks:load', function() {
  amendLeedsBillingFields();
});

$(document).on('change', '.prefill-school-select', function() {
  var selectedId = $('.prefill-school-select option:selected').val();
  $.get('/schools/prefill', { id: selectedId });
});

$(document).on('click', '.prefill-school-billing-address', function() {
  if($(this).data('checked') === false) {
    var schoolAddressLine1 = $('#school_address_line_1').val();
    $('#school_billing_address_line_1').val(schoolAddressLine1);
    var schoolAddressLine2 = $('#school_address_line_2').val();
    $('#school_billing_address_line_2').val(schoolAddressLine2);
    var schoolTown = $('#school_town').val();
    $('#school_billing_town').val(schoolTown);
    var schoolPostcode = $('#school_postcode').val();
    $('#school_billing_postcode').val(schoolPostcode);
    var schoolTelephone = $('#school_telephone').val();
    $('#school_billing_contact_number').val(schoolTelephone);
    $(this).data('checked', true);
  } else {
    $('#school_billing_address_line_1, #school_billing_address_line_2, #school_billing_town, #school_billing_postcode, #school_billing_contact_number').parents('.field').show();
    $(this).data('checked', false)
  }
});

$(document).on('change', '.questionnaire-login-select', function() {
  var element = $(this).data('select');
  if($(this).find('option:selected').val() != '') {
    $('.' + element).prop('checked', true);
  } else {
    $('.' + element).prop('checked', false);
  }
});
