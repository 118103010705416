var hoverEventInitializer;
if (typeof Turbolinks == 'undefined') {
  hoverEventInitializer = 'DOMContentLoaded'
} else {
  hoverEventInitializer = 'turbolinks:load';
}

document.addEventListener(
  hoverEventInitializer,
  function () {
    var divs = document.querySelectorAll('[data-hover]'), i;

    for (i = 0; i < divs.length; ++i) {
      let currentDiv = divs[i];
      currentDiv.addEventListener('mouseenter', function () {
        this.src = currentDiv.getAttribute('data-hover');
      }, false);

      currentDiv.addEventListener('mouseleave', function () {
        this.src = currentDiv.getAttribute('data-standard');
      }, false);
    }
  }
)
