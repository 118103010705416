import Cookies from 'js-cookie';
import $ from 'jquery';

function modalEvent(event) {
  document.querySelector('.lessons-modal').classList.remove('visible');
  Cookies.set('lessons-modal', '1');
  event.preventDefault();
}

// IE support: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

document.addEventListener('click',
  function (event) {
    if (event.target.matches('.lessons-modal-acceptance-form__submit')) {
      modalEvent(event);
    }
  },
  false
);

function turbolinksCheck() {
  return (typeof Turbolinks == 'undefined') ? 'DOMContentLoaded' : 'turbolinks:load';
}

let initializer = turbolinksCheck();

document.addEventListener(
  initializer,
  function () {
    if (Cookies.get('lessons-modal') != 1 && document.querySelector('.lessons-modal')) {
      document.querySelector('.lessons-modal').classList.add('visible');
    }
  }
);

$(document).on('change', '.lessons-modal-acceptance-form__check-box', function() {
  if($(this).prop('checked') == true) {
    $('.lessons-modal-acceptance-form__submit').removeAttr('disabled');
  } else {
    $('.lessons-modal-acceptance-form__submit').attr('disabled', 'disabled');
  }
})
